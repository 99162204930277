export const customerToken = "";
/* export const basicAuthToken = "2c2e76c98ddf56804caccf55ea1cd0b1af791357351206ec980e8036d98ea6174dd24ec073ccb896505de54f09ea1ca1dd3b115c547e4c57ce06aab3";
export const basicAuth = "Basic 2c2e76c98ddf56804caccf55ea1cd0b1af791357351206ec980e8036d98ea6174dd24ec073ccb896505de54f09ea1ca1dd3b115c547e4c57ce06aab3";
export const BaseUrl = "http://docib-laravel-local.com/api/V1/"; */
export const basicAuthToken = "14f2ac405a499714b2d8e220083e3d25366e09bc40458a5a016922ea48abca8dbc778456475481c8be08d32b643b35359ff65294ab6e6dec829414f9";
export const basicAuth = "Basic 14f2ac405a499714b2d8e220083e3d25366e09bc40458a5a016922ea48abca8dbc778456475481c8be08d32b643b35359ff65294ab6e6dec829414f9";
export const BaseUrl = "https://admin-staging.docibonline.com/api/V1/";



export const getHome = () => (
    "Home/getHomeData"
)
export const getAppIconUrl = () => (
    "Home/getAppIcon"
)

export const getOrderListData = () => (
    "Transaction/getOrderList"
)
export const cancelOrderEndpoint = () => (
    "Transaction/cancelOrder"
)
export const precriptionItemRemoveEndpoint = () => (
    "Transaction/deletePrescribedCartProduct"
)

export const addPrecriptionDataEndpoint = () => (
    "Product/addPrescription"
)

export const getPrecriptionStatusEndpoint = () => (
    "Product/getPrescription"
)
export const getPrescriptionDetailsEndpoint = () => (
    "Product/getPrescriptionDetails"
)

export const getCartApi = () => (
    "Transaction/cartProductList"
)
export const getCartPrecriptionApi = () => (
    "Transaction/prescribedCartProductList"
)
export const getAddressEndpoint = () => (
    "Auth/userAddressList"
)

export const getOrderDetailsbyId = () => (
    "Transaction/getOrderDetails"
)
export const getAddAddressEndpoint = () => (
    "Auth/addUserAddress"
)

export const getHandleDetailsApiUrl = () => (
    "Product/getHandleDetails"
)

export const getEmiratesEndpoint = () => (
    "Auth/getEmirates"
)

export const getCategoryCollectionUrl = () => (
    "Product/getCategoryCollection"
)
export const getAllCategoryProductsUrl = () => (
    "Product/getAllCategoryProducts"
)
export const getAllCollectionProductsUrl = () => (
    "Product/getAllCollectionProducts"
)
export const getAllFeaturedProductsUrl = () => (
    "Product/getAllFeaturedProducts"
)
export const getAllBrandProductsUrl = () => (
    "Product/getAllBrandProducts"
)
export const getAllSimilarProductsUrl = () => (
    "Product/getAllSimilarProducts"
)
export const getAllOfferProductsUrl = () => (
    "Product/getAllOfferProducts"
)
export const getAllSearchProductsUrl = () => (
    "Product/search"
)
export const getAllProductsUrl = () => (
    "Product/productSearch"
)
export const getBrandsUrl = () => (
    "Product/getBrands"
)
export const getPriceRangeUrl = () => (
    "Product/getPriceRange"
)
export const getOffersUrl = () => (
    "Product/getOffers"
)
export const getFilterCollectionsUrl = () => (
    "Product/getFilterCollections"
)
export const getTagsUrl = () => (
    "Product/getTags"
)
export const getProductDetailsUrl = () => (
    "Product/getProductDetails"
)
export const getVariantProductDetailsUrl = () => (
    "Product/getVariantProductDetails"
)
export const getProductShareUrl = () => (
    "Product/getProductShareUrl"
)
export const addUserProductReviewUrl = () => (
    "Product/addUserProductReview"
)

export const getEditAddressEndpoint = () => (
    "Auth/editUserAddress"
)
export const getDeleteAddressEndpoint = () => (
    "Auth/deleteUserAddress"
)
export const addToCartUrl = () => (
    "Transaction/addToCart"
)
export const wishlistUrl = () => (
    "Product/addToWishlist"
)
export const writeReviewUrl = () => (
    "Product/addUserProductReview"
)
export const getUserLoginUrl = () => (
    "Auth/login"
)
export const getUserRegisterUrl = () => (
    "Auth/userRegistration"
)
export const getUserSendOtpUrl = () => (
    "Auth/resendOTP"
)
export const getUserVerifyOtpUrl = () => (
    "Auth/verifyOTP"
)
export const getUserTokenUpdateUrl = () => (
    "Auth/updateToken"
)
export const getUserProfileUrl = () => (
    "Auth/myProfile"
)
export const getUserGetInterestsUrl = () => (
    "Auth/getInterest"
)
export const getUserUpdateInterestsUrl = () => (
    "Auth/updateInterest"
)
export const getUserLogoutUrl = () => (
    "Auth/logout"
)
export const getWishlistUrl = () => (
    "Product/getUserWishlistProducts"
)
export const getPrivacyPolicyUrl = () => (
    "Auth/getPrivacyPolicy"
)
export const getCMSPagesUrl = () => (
    "Auth/getCMSPages"
)
export const getTermsconditionUrl = () => (
    "Auth/getTermsCondition"
)
export const getRefundAndExchangeUrl = () => (
    "Auth/getRefundAndExchangePolicy"
)
export const getFaqUrl = () => (
    "Auth/getFaq"
)
export const getAllCategorysUrl = () => (
    "Product/getAllCategory"
)
export const getEditProfileUrl = () => (
    "Auth/editProfile"
)
export const getOrderDetailsUrl = () => (
    "Transaction/getOrderDetails"
)
export const getInterestsUrl = () => (
    "/Auth/getInterest"
)
export const getUpdateInterestsUrl = () => (
    "/Auth/updateInterest"
)
export const getUserNotificationsUrl = () => (
    "/Auth/getNotification"
)

export const getGoogleAutocompleteFullUrl = () => (
    "https://maps.googleapis.com/maps/api/place/autocomplete/json"
)
export const getGooglePlaceDetailsFullUrl = () => (
    "https://maps.googleapis.com/maps/api/place/details/json"
)
export const getGoogleGeocodeFullUrl = () => (
    "https://maps.google.com/maps/api/geocode/json"
)

export const applyCoupenUrl = () => (
    "Transaction/applyCoupon"
)

export const placeOrderUrl = () => (
    "Transaction/placeOrder"
)

export const telrOrderCreationUrl = () => (
    "Transaction/telrOrderCreation"
)

export const telrOrderResultUrl = () => (
    "Transaction/telrTransactionResults"
)

export const postPayOrderCreationUrl = () => (
    "Transaction/postpayOrderCreation"
)

export const postPayOrderResultUrl = () => (
    "Transaction/postpayTransactionResults"
)

export const updateOrderStatusUrl = () => (
    "Transaction/updateOrderStatus"
)

export const emailSubScribeUrl = () => (
    "Home/emailSubscribe"
)
export const getAllReturnReasonUrl = () => (
    "Transaction/getUserReturnReasons"
)
export const getSiteMapUrl = () => (
    "Home/getSitemapXml"
)
export const createOrderReturnUrl = () => (
    "Transaction/createOrderReturn"
)





